import React from "react";
import Layout from "../../components/Layout";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";

export default class BlogOverviewPage extends React.Component {
  render() {
    const { data } = this.props;
    const { edges: posts } = data.allMarkdownRemark;

    return (
      <Layout>
        <section className="section">
          <div className="container">
            <div className="content">
              <h1 className="is-size-2">Latest Blogs</h1>
            </div>
            <div className="columns is-multiline">
              {posts.map(({ node: post }) => (
                <div className="content column is-one-third" key={post.id}>
                  <div className="news-image">
                    <img
                      src={post.frontmatter.featured}
                      alt={post.frontmatter.title}
                    />
                    <div className="news-image-hover">
                      <Link
                        to={post.fields.slug}
                        className="button is-primary is-medium is-outlined"
                      >
                        Keep Reading →
                      </Link>
                    </div>
                  </div>
                  <Link to={post.fields.slug}>
                    <div className="news-title">
                      <p>{post.frontmatter.date}</p>
                      {post.frontmatter.title}
                    </div>
                  </Link>
                  {/*  <div className="news-content">
                      <p>
                        {post.excerpt}
                      </p>
                    </div>*/}
                </div>
              ))}
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}

BlogOverviewPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      edges: PropTypes.array
    })
  })
};

export const pageQuery = graphql`
  query BlogQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { templateKey: { eq: "blog-post" } } }
    ) {
      edges {
        node {
          excerpt(pruneLength: 200)
          id
          fields {
            slug
          }
          frontmatter {
            title
            featured
            templateKey
            date(formatString: "MMMM DD, YYYY")
          }
        }
      }
    }
  }
`;
